<template>
  <div class="search-container">
    <!-- <p class="search-title">全局搜索</p> -->
    <div :class="{ 'flex aj_c ai_c': !list || !list.length }" class="search-content">
      <t-pagination v-if="list && list.length" @currentChange="currentChange" @sizeChange="sizeChange" :pages="pages">
        <div slot="table" class="search-box">
          <div class="search-box-head flex flex jc_b ai_c">
            <div class="fullName">文件名称</div>
            <div class="type">文件类型</div>
            <div class="more">操作</div>
          </div>
          <div class="search-box-body">
            <div class="search-items flex jc_b ai_c" @click="onItems(v)" v-for="v in list" :key="v.id">
              <div class="fullName" v-html="v.fullName"></div>
              <div class="type">{{ v.isFile ? '文件' : '文件夹' }}</div>
              <div class="more">查看</div>
            </div>
          </div>
        </div>
      </t-pagination>
      <div v-else class="nothing-box" style="margin-top: -100px;">
        <i class="search-not-icon" />
        <p class="not-tit">暂无内容</p>
        <p class="not-txt">更换搜索关键字试试</p>
      </div>
    </div>
  </div>
</template>

<script>
import pagination from '@/mixins/pagination'

export default {
  mixins: [pagination],
  data() {
    return {
    }
  },
  watch: {
    curGroupId(val) {
      
      val && this.init()
    },
    '$route'() {
     
      if (this.$route.params.name) {
        this.init()
      }
    }
  },
  mounted() {
  
  },
  methods: {
   
      init() {
        this.pageParams.page = 1
        this.getList()
      },
      getList() {
        const { pageParams } = this
        const { name } = this.$route.params

        this.$api.home.getSearch({
          ...pageParams,
          name
        }).then(res => {
          console.info(res, 40)
          this.list = res.items.map(v => {
            console.info()
            return {
              ...v,
              name: v.name.replace(RegExp(name, 'g'), `<font color="#056BFD">${name}</font>`)
            }
          })
          this.pages = res.page
        })
      },
      // 点击
      onItems(row) {
        if (row.typeId === 52) {
          const { href } = this.$router.resolve({
            name: 'graph',
            query: {
              id: row.id
            }
          });
          window.open(href, '_blank')
          return
        }
        if (row.isFile) {
          if (!row.path) return

          return window.open(row.path)
        }

        switch (row.belongId) {
          case 90: this.$router.push({
            name: 'case',
            query: { id: row.id }
          }); break;
          case 92: this.$router.push({
            name: 'privately',
            query: { id: row.id }
          }); break;
          case 93: this.$router.push({
            name: 'affair',
            query: { id: row.id }
          }); break;
        }
      }
    },
    created() {
      this.init()
    }
  }
</script>

<style lang="scss" scoped>
.search-container {
  height: 100%;
  background: #fff;
  padding: 24px;
  border-radius: 16px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0;
  }

  .search-title {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 28px;
    color: $primary_title;

  }

  .search-content {
    min-height: 100%;
  }

  .search-box {
    .search-box-head {
      padding: 0 24px;
      height: 46px;
      background: #F4F7FA;
      border-radius: 8px 8px 0px 0px;
    }

    .search-box-body {
      .search-items {
        height: 50px;
        cursor: pointer;
        padding: 0 24px;

        &:not(:first-child) {
          border-top: 1px solid #F4F7FAFF;
        }

        .more {
          color: $primary;
        }

        .fullName {
          color: #60707D;
        }
      }
    }

    .type,
    .more {
      min-width: 80px;
      text-align: center;
    }

    .fullName {
      min-width: 750px;
    }

    .type,
    .more,
    .fullName {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: $primary_title;
      line-height: 20px;
    }
  }
}</style>
